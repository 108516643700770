@media (min-width:1440px) {}


@media (max-width: 1439.98px) {

    /* .order_detail_sec .order_detail_inner{
        padding: 5px 25px;
    } */
    .order_detail_sec .social_des ul li {
        padding: 5px 7px;
        height: auto;
        width: auto;
        text-align: center;
    }

    .order_detail_sec .btn_submit {
        margin-top: 18px !important;
    }

    /* .product_gallery .image-gallery-content .image-gallery-slide .image-gallery-image{
        height: 307px;
    } */
}

@media (min-width: 1200px) {}

@media (max-width: 1199.98px) {
    .sold_detail_tag {
        top: 0;
        right: 0;
        left: 0;
        position: static;
        display: inline-block;
        margin-bottom: 10px;
    }


    html {
        font-size: 12px;
    }

    .order_detail_sec .social_des ul li {
        margin-bottom: 7px;
    }

    .bread_sec .bread_sec_inner ul li:nth-child(1) a:after {
        top: 9px;
    }

    .order_detail_sec .order_detail_inner {
        padding: 8px 3px 3px 12px;
    }

    .buy_section .container {
        padding: 2.28rem 2.64rem;
    }

    .buy_section .buy_content h1 {
        font-size: 2rem;
    }

    .sec_heading h2 {
        font-size: 2rem;
    }

    .service_category .service_icon h2 {
        font-size: 1rem;
    }

    .service_category .service_icon {
        padding: 50px 9px;
    }

    .service_category,
    .top_product_sec,
    .partner_logo,
    .faq {
        padding: 2rem 0rem;
    }

    footer {
        padding-top: 2rem;
    }

    footer .copyright {
        margin-top: 2rem;
    }

    .banner-section .slick-prev,
    .banner-section .slick-next {
        width: 94px !important;

        height: 67px !important;
    }

    .banner-section .slick-slide img {
        height: 501px;
    }

    .top_product_sec .slick-prev {
        left: 92%;
    }

    .top_product_sec .product_content .product_content_cn h3 {
        font-size: 1.07rem;
    }

    .top_product_sec .product_content .product_content_cn p {

        font-size: 0.92rem;
    }

    .top_product_sec .product_content .product_content_cn a {
        font-size: 1rem;
    }

    .top_product_sec .product_content .product_content_cn {
        padding: 18px 10px;
    }

    header form input[type=text] {
        width: 100%;
    }

    header form .fa {
        right: 12px;
        top: 33%;
    }

    .box_list .name {
        min-height: 50px;
    }

    .dash_logo img {
        width: 12rem;
    }

    .dashboard_outer {
        padding-top: 65px;
    }

    .dashboard_sidebar {
        top: 65px;
    }

    .top_product_sec .product_content .product_content_cn {
        padding: 18px 17px;
        min-height: 139px;}

}

@media(max-width:991.98px) {
    header .top_header .logo {
        text-align: center;
        margin: 0 auto;
        display: block;
        margin-bottom: 13px;
        display: none;
    }

    .modal-content {
        width: 100%;
        height: 100%;
    }

    .dashboard_inner .change_password {
        width: 100%;
    }

    .enquiry_form .enquiry_form_inner {
        width: 100%;
    }

    .contact_faq {
        padding: 0;
    }

    .contact_faq .faq .contact_form {
        margin-left: 0px;
    }

    .navbar-toggler {
        padding: 4px 11px;
    }


    header form input[type=text] {
        border: none;
    }

    header form {
        position: absolute;
        right: 0px;
        top: 10px;
        left: 0px;
        z-index: 2;
    }

    header form input[type=text] {
        width: calc(100% - 171px);
        height: 45px;

    }

    header .navbar .navbar-brand img {
        max-width: 164px;
    }

    /* .navbar-toggler-icon{
    background-color: #077a3b;
    border-radius: 50px;
    border: 3px solid #fff;
    } */
    .navbar-toggler-icon {
        margin: 5px 0px;
        background-color: #077a3b;
        height: 2px;
        width: 23px;
        display: block;
    }

    header .top_header {
        padding: 0px 0px 0px 0px;
    }

    .navbar-toggler {
        background-color: #d3e1e1;
        border-radius: 4px;
        outline: none;
        padding: 9px 11px;
    }

    header .navbar ul li a:before {
        display: none;
    }

    header .navbar-expand-lg .navbar-nav .dropdown-menu {
        left: 0px;
    }

    header .menu_header .navbar-text a {
        padding: .6rem 0px;
    }

    .banner-section .slick-slide img {
        height: 407px;
    }

    .buy_section .container {
        padding: 2rem 2rem;
    }

    .faq .contact_form .btn_submit {
        height: 46px;
    }

    .link-heading {
        font-size: 1.10rem;
    }

    footer .copyright {
        margin-top: 1rem;
    }

    footer .quikc-heading ul li a {
        font-size: 15px;
    }

    .faq .contact_form span {
        font-size: 13px;
        margin-bottom: 1rem;
        line-height: 1.5;
    }

    .top_product_sec .slick-prev {
        left: 89%;
    }

    .faq .contact_form input[type=text],
    input[type=email] {
        height: 46px;
    }

    .faq .contact_form h4 {
        font-size: 1.28rem;
    }

    .buy_section .buy_link a {
        padding: 7px 22px;
    }

    .service_category .service_icon {
        height: 147px;
    }

    .service_category .service_icon {
        padding: 38px 9px;
    }

    .buy_section .buy_content h1 {
        font-size: 1.5rem;
    }

    .buy_section .buy_content p {
        font-size: 1rem;
    }

    .banner-section .slick-prev,
    .banner-section .slick-next {
        width: 87px !important;
        height: 59px !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    header .navbar .navbar-brand {
        display: block !important;

    }

    header .menu_header {
        background-color: #077a3b;
        height: 120px;
        display: flex;
        align-items: flex-end;

        padding: 8px 0px;
    }

    header #basic-navbar-nav {
        position: absolute;
        background-color: #077a3b;
        width: 100%;
        top: 61px;
        z-index: 9999;
        box-shadow: 0px 3px 0px 0px rgb(27 23 26 / 20%);
    }

    header #basic-navbar-nav ul li a {
        margin: 0.10rem 1rem;
    }

    .mobile_product_sec {
        background-color: #fff;
    }

    .story_sec {
        padding: 2rem 0rem;
    }


    .box_list {
        width: 48%;
    }



    .dashboard_sidebar {
        left: -120px;
        transition: 0.4s ease all;
        visibility: hidden;
        opacity: 0;
        box-shadow: none;
        top: 62px;
        padding: 20px 0px;
    }

    .dashboard_inner {
        width: 100%;
        margin-left: 0;
        padding: 25px 20px 2.30rem;
    }

    .toggle_btn_outer {
        position: absolute;
        left: 0;
        top: 62px;
        transition: 0.4s ease all;
    }

    a.toggle_btn {
        width: 36px;
        height: 36px;
        background: #007f3a;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 4px 4px 0;
        z-index: 99;
        position: fixed;
        font-size: 20px;

    }

    .show_sidebar .toggle_btn_outer {
        left: 300px;
    }

    .show_sidebar .dashboard_sidebar {
        left: 0;
        z-index: 9;
        visibility: visible;
        opacity: 1;
    }

    .show_sidebar .sidebar_overlay {
        opacity: 0.7;
        visibility: visible;
    }

    .dashboard_profile h5 {
        font-size: 1.28rem;
    }


}

@media (max-width: 767.98px) {

    .banner-section .slick-slide img {
        height: 429px;
    }

    .accordion__button {
        line-height: 1.3;
    }

    .order_detail_sec .order_detail_inner {
        padding: 8px 3px 0px 13px;
    }

    header .login_menu ul li:nth-child(1):after {
        display: none;
    }

    header .login_menu ul li {
        display: block;
    }



    .product_gallery .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 206px;
    }

    /* .product_gallery{
        padding: 5px 27px;
    } */
    .product_gallery .image-gallery-left-nav {
        left: 0px;
    }

    .product_gallery .image-gallery-right-nav {
        right: 0;
    }

    .our_team_sec .team_heading_sec {
        width: 100%;
    }

    .about_sec,
    .marketplace_sec {
        padding: 2rem 0rem;
    }

    .about_company {
        padding-top: 1rem !important;
        padding-bottom: 17px;
    }

    .marketplace_sec .marketplace_inner_col h4 {
        font-size: 13px;
    }

    .story_sec .story_inner h2 {
        font-size: 1.57rem;
    }

    .marketplace_sec .marketplace_inner_col h2 {
        font-size: 19px;
    }

    .circle {
        left: 45%;
    }

    .our_team_sec {
        height: 291px;
    }

    .team_photo_sec {
        margin-top: -122px;
        padding-bottom: 26px;
    }

    .our_team_sec .team_heading_sec p {
        font-size: 1rem;
    }

    .team_photo_sec .slick-slide img {
        padding: 0px 3px;
    }

    .banner-section .slick-prev {
        left: 1% !important;
    }

    .banner-section .slick-next {
        right: 1% !important;
    }

    .buy_section .buy_content h1 {
        font-size: 1.2rem;
    }

    .buy_section .container {
        padding: 1.5rem 1.5rem;
    }

    .faq .contact_form {
        margin-left: 0px;
    }

    header form input[type=text] {
        width: calc(100% - 142px);
        height: 39px;
        font-size: 12px;
    }

    .navbar-toggler {
        padding: 5px 11px;
    }

    header .navbar .navbar-brand img {
        max-width: 134px;
    }

    .banner-section .slick-slide img {
        height: 328px;
    }

    .top_product_sec .slick-prev {
        left: 86%;
    }

    header form .fa {
        right: 9px;
        font-size: 16px;
    }

    .sec_heading:after {

        display: none;
    }

    header #basic-navbar-nav {
        top: 53px;
    }

    .slick-dots li button:before {
        font-size: 10px;
    }

    .slick-dots li.slick-active button:before {
        color: #077a3b;
    }

    .partner_logo .slick-dots li button:before {
        opacity: 1;
        color: white;
    }

    .partner_logo .slick-dots li.slick-active button:before {
        color: #077a3b;
    }

    .login_signup_sec .login_signup_form {
        width: 100%;
    }

    .Search_sec {
        padding: 1rem;
    }


    .container-fluid {
        padding: 0 15px;
    }

    .sidebar_sec {
        display: none;
    }

    .sidebar_sec {
        display: block !important;
    }

    div#left_sidebar {
        display: none;
    }

    .bg_grey {
        flex-wrap: wrap;
    }

    .content,
    .email_box {
        width: 100%;
    }

    .pagination_deshbaord {
        padding: 1.5rem 0rem;
    }

    .dash_logo img {
        width: 8rem;
    }

    .dashboard_outer {
        padding-top: 55px;
    }

    .DashHeader_right .user img {
        width: 3rem;
        height: 3rem;
    }

    .dashboard_inner .dash_main_inner {
        padding: 1rem;
        margin-top: 32px;
    }

    .profile_userimg {
        width: 8rem;
        height: 8rem;
    }

    .dashboard_menu ul li a {
        padding: 10px 1rem;
    }

}

@media(max-width:635px) {

    .top_product_sec .slick-dots {
        bottom: -28px;
    }
    .top_product_sec .slick-dots {
        bottom: -30px!important;
    }
    .service_category .slick-prev,
    .service_category .slick-next,
    .top_product_sec .slick-prev,
    .top_product_sec .slick-next,
    .partner_logo .slick-prev,
    .partner_logo .slick-next,
    .team_photo_sec .slick-prev,
    .team_photo_sec .slick-next {
        display: none !important;
    }
    .service_category, .top_product_sec, .partner_logo, .faq {margin-bottom: 10px;}

.service_category, .top_product_sec, .partner_logo, .faq {padding: 24px 0 36px 0!important;} 

.top_product_sec .slick-dots {bottom: 0;}
.partner_logo {padding-bottom: 50px!important;}

.partner_logo .slick-dots {bottom: -40px;}

.slick-dots li {margin: 0;}


}

@media (max-width: 575.98px) {

    .sec_heading h2,
    .contact_heading h2 {
        font-size: 1.71rem;
        margin-bottom: 7px;
    }

    .order_detail_sec .order_detail_inner .price,
    .descpt {
        font-size: 1rem;
    }

    .order_detail_sec .order_detail_inner h2 {
        font-size: 1.07rem;
    }

    .product_gallery .image-gallery-left-nav,
    .product_gallery .image-gallery-right-nav {
        width: 46px;
        height: 38px;
        font-size: 13px;
    }

    header form .btn_search {
        right: 17px;
    }

    .enquiry_form .btn {
        height: 35px;
        padding: 0px;
    }

    .react-datepicker__input-container {
        margin-bottom: 13px;
    }

    .modal-content {
        padding: 1.07rem 1.5rem 1.92rem 1.5rem;
    }

    .dashboard_inner .change_password .cancel_button ul li:nth-child(2) a {
        height: 37px;
    }

    .dashboard_inner .change_password input[type=password] {
        height: 3.6rem;
    }

    .main_notification1 .notification_name .notify_user .name_user span {
        margin-right: 11px;
    }

    .order_detail_sec .order_detail_inner ul li:nth-child(odd):after {
        display: none;
    }

    .enquiry_form .enquiry_form_inner form p {
        width: 100%;
    }

    .enquiry_form .enquiry_form_inner {
        padding: 1.21rem 1.64rem 1.78rem 1.78rem;
    }

    .team_photo_sec {
        margin-top: -98px;

    }

    .circle {
        left: 44%;
    }

    .marketplace_inner_col {
        margin-bottom: 11px;
    }

    .login_signup_sec .login_signup_form input[type=submit] {
        padding: 0px;
        height: 35px;
    }

    .login_signup_sec .login_signup_form .form_logo img {
        max-width: 123px;

    }

    .login_signup_sec .login_signup_form .login_text span {
        font-size: 12px;
    }

    .login_signup_sec .login_signup_form .form-control {
        height: 41px;
    }

    .banner-section .slick-prev,
    .banner-section .slick-next {
        top: 50%;
    }

    .buy_section {
        margin-top: -7px;
    }

    header form {

        width: 100%;
        padding: 0px 15px;
    }

    header form .fa {
        right: 30px;
    }

    .navbar-toggler {
        padding: 2px 7px;
    }

    .inner_heading h2 {
        font-size: 1.3rem;
    }

    header form input[type=text] {
        font-size: 12px;
        width: 100%;

    }

    .banner-section .slick-prev:before,
    .banner-section .slick-next:before {
        font-size: 27px !important;
    }

    .social-footer-div {
        margin-top: 8px;
    }

    .faq .contact_form {
        padding: 22px 22px 22px 22px;
    }

    .faq .contact_info h3 {
        font-size: 1.3rem;
    }

    .banner-section .slick-slide img {
        height: 183px;
    }

    header #basic-navbar-nav ul li a {
        margin: 0px 9px;
        font-size: 14px;
    }

    .banner-section .slick-prev,
    .banner-section .slick-next {
        width: 55px !important;
        height: 39px !important;
    }

    .buy_section .buy_content h1 span {
        background-color: transparent;
        padding: 0px;
        color: #ffffff;
    }

    .buy_section .buy_link {
        text-align: left !important;
        margin-top: 8px;
    }

    .buy_section .buy_link a {
        padding: 7px 11px;
    }

    header .menu_header {
        padding: 8px 15px;
    }

    .faq .contact_form .btn_submit {
        height: 35px;
    }

    .login_signup_sec .login_signup_form {
        padding: 19px 24px;
    }

    .about_sec .about_sec_com h2 {
        font-size: 1.30rem;
    }

    .dashboard_inner {
        padding: 15px;
    }

    .filter_div .Search_sec {
        width: 100%;
    }

    .discussion_notify {
        flex-wrap: wrap;
        padding: 0.78rem;
    }

    .discussion_notify .box_discount {
        width: 100%;
        text-align: left;
        margin-left: 5.50rem;
    }

    .discussion_notify .alternate_image {
        width: 5rem;
        height: 5rem;
    }

    .discussion_notify .lerory {
        width: calc(100% - 5rem);
    }

    .discussion_notify .lerory h2 {
        font-size: 1.14rem;
    }

}

@media(max-width:479.98px) {
    .list_wrapper .box_list .btn_box {position: initial;margin: 0;}

    .list_wrapper .box_list {padding-bottom: 15px;}

    .top_product_sec .product_content .product_content_cn {         
        min-height:initial;
    }
    .sidebar .btn,.custom_check{font-size:1rem;}
    .top_product_sec .product_content .product_content_cn .btn-change7 {position: inherit;width: 100%;margin: 0;}

    .mobile_dflex {
        flex-direction: column;
    }

    .bread_sec .bread_sec_inner ul li:nth-child(1) a:after {
        top: 5px;
    }

    .bread_sec ul li a {
        font-size: 0.8rem;
    }

    .main_notification1 .notification_name .notify_user .time h3 {
        margin-top: 4px;
    }

    .notify_user {
        flex-direction: column;
        justify-content: unset;
        align-items: initial !important;
    }

    .login_text {
        margin: auto !important;
    }

    .term_condition_checkbox span {
        width: 21px;
        height: 21px;
    }

    .term_condition_checkbox label {
        padding-left: 2.2rem;
    }

    .list_wrapper {
        margin: 0;
    }

    .box_list {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .box_list .name {
        min-height: 100%;
    }

    .box_list img {
        width: 100%;
    }

    .box_list .view_link {
        font-size: 1rem;
    }
}

@media(max-width:324.98px) {
    .buy_section .buy_content h1 {
        font-size: 1rem;
    }

    .circle {
        left: 41%;
    }

    .sec_heading h2,
    .contact_heading h2 {
        font-size: 1.35rem;
    }

    .buy_section .buy_link a {
        padding: 5px 11px;
        font-size: 11px;
    }

    .buy_section .buy_content p {
        margin-bottom: 0px;
    }
}



/* media-query */
