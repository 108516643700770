@import "~react-image-gallery/styles/css/image-gallery.css";
/* COMMON APP CSS */

html {
    font-family: 'poppinsregular';
    font-size: 14px;
}

p {
    font-family: 'poppinslight';
}

.sold_tag {
    position: absolute;
    padding: 0.35rem 1rem;
    font-size: 1rem;
    top: 1rem;
    background-color: red;
    color: white;
}

.sold_detail_tag {
    position: absolute;
    padding: 0.35rem 1rem;
    font-size: 1rem;
    top: 0rem;
    left: -70px;
    background-color: red;
    color: white;
}

body {
    font-family: 'poppinsregular';
    font-size: 1.14rem;
    color: #353535;
    font-weight: 300;
    line-height: normal;
    position: relative;
    background-color: #ffffff;
    overflow-x: hidden;
}

.marginmessage,
ErrorMessage {
    margin-top: 10px;
    font-size: 12px;
}

.profile_userimg img {
    height: 125px !important;
}

.notify-count {

    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 5px;
    background: red;
    border: 2px solid red;
    color: #fff;
    text-align: center;
}

.catactive a {
    color: blue !important;
}

.noRecord {
    text-align: center !important;
    margin: auto;
    width: 50%;

    padding: 10px;
    color: #898989;
    margin-top: 175px;
}

.equipment_images img {
    height: 200px;
    width: 100%;
    object-fit: cover;

}

.usearch {
    margin-top: 13px;
}

.top-product-slider img {
    height: 200px;
    width: 100%;
    object-fit: cover;

}

@font-face {
    font-family: 'poppinssemibold';
    src: url('../font/poppins-semibold-webfont.woff2') format('woff2'),
        url('../font/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../font/MyriadPro-Regular.woff2') format('woff2'),
        url('../font/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../font/Lato-Semibold.woff2') format('woff2'),
        url('../font/Lato-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'poppinsregular';
    src: url('../font/poppins-regular-webfont.woff2') format('woff2'),
        url('../font/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinslight';
    src: url('../font/poppins-light-webfont.woff2') format('woff2'),
        url('../font/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsmedium';
    src: url('../font/poppins-medium-webfont.woff2') format('woff2'),
        url('../font/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansbold';
    src: url('../font/opensans-bold-webfont.woff2') format('woff2'),
        url('../font/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsbold';
    src: url('../font/poppins-bold-webfont.woff2') format('woff2'),
        url('../font/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* font */


header {
    background-color: #fff;
    transition: all 0.5s;
}

.sec_heading h2 {
    font-family: 'poppinssemibold';
    font-size: 2.5rem;
    font-weight: 600;
    color: #000;
}

.sec_heading {
    position: relative;
}

.sec_heading:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 165px;
    /* background-color: aquamarine; */
    text-align: center;
    margin: 0 auto;
    display: block;
    background-color: rgb(2 2 2 / 27%);
    left: 43%;
    bottom: -38%;
}

::placeholder {
    color: rgb(192, 192, 192);
}

.inner_heading a:hover {
    color: #077a3b;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0);
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #b1b1b1;
    height: 3px;
}

/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
}

img {
    max-width: 100%;
    display: inline-block;
}

button,
input,
textarea,
select {

    opacity: 1;
    letter-spacing: 0.0143rem;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
    outline: none !important;
    box-shadow: 0rem 0rem 0rem transparent !important;
}

ul,
li {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

a {
    display: inline-block;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: #348ad2;
    cursor: pointer;
}

a:hover,
a:focus {
    outline: inherit;
    text-decoration: none;
    color: #348ad2;
}

a:hover {
    color: #2e56bf;
}

h1,
h2,
h3,
h4,
h5,
h6 {

    line-height: normal;
    padding: 0px 0px;
    margin: 0px 0px;
}

.link {
    color: #348ad2 !important;
}

.alert {
    position: relative;
    z-index: 99;
}

section::after {
    display: block;
    clear: both;
    content: "";
}


/**===top-product===**/
/* section.top_product_sec .slick-initialized .slick-slide.slick-cloned {
    display: none;
} */

/* 
section.service_category .slick-slide.slick-cloned {
    display: none;
}
 */
/* section.top_product_sec .slick-track {
    width: auto !important;
} */







.full_container {
    max-width: 1920px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0rem auto;
}

.form-control::-webkit-input-placeholder {
    color: #b8b8b8;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #b8b8b8;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #b8b8b8;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #b8b8b8;
    opacity: 1;
}

.form-control::placeholder {
    color: #b8b8b8;
    opacity: 1;
}

.form-control {
    height: 3.8rem;
    font-size: 13px;
    color: #757575;
    font-weight: 300;
    border-radius: 0px;
    border: 1px solid #e6e6e6;
    padding: 0.8rem 1rem;
}

.btn {
    background-color: #2f7dc9;
    border: 1px solid #2f7dc9;
    font-family: 'poppinssemibold';
    border-radius: 0.25rem;
    font-size: 1.21rem;
    color: #fff;
    font-weight: 500;
    transition: 0.4s ease all;
    padding: 0.85rem 2rem;
}

.btn-cancel {
    background-color: #ff4d4d;
    border: 1px solid #ff4d4d;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    transition: 0.4s ease all;
    padding: 0.6rem 0.8rem;
}

.btn-cancel:hover {
    background: #ffffff;
    color: #ff4d4d;
    border: 1px solid #ff4d4d;
}

.btn-track {
    background-color: #3cb371;
    border: 1px solid #3cb371;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    transition: 0.4s ease all;
    padding: 0.6rem 0.8rem;
}

.btn-track:hover {
    background: #ffffff;
    color: #3cb371;
    border: 1px solid #3cb371;
}

button:focus {
    border: 0;
    outline: none;
}

.btn:hover {
    background: #ffffff;
    color: #2f7dc9;
    border: 1px solid #2f7dc9;
}

select.form-control {
    font-size: 1.07rem;
    color: #757575;
    font-weight: 300;
    appearance: none;
    background-image: url("/assets/img/custom_select.svg");
    background-size: 0.75rem;
    background-repeat: no-repeat;
    background-position: right 1.07rem center;
    padding-right: 2.9rem;
}

/* Custom Checkbox */
.custom_check {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 1.6rem;
    padding-left: 2.14rem;
}

.custom_check input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin: 0px 0px;
    z-index: 2;
}

.custom_check .check_indicator {
    height: 1.57rem;
    width: 1.57rem;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
    width: 0.5rem;
    height: 0.86rem;
    position: absolute;
    left: 0.43rem;
    top: 2px;
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
}

.custom_check input:checked+.check_indicator {
    background: #348ad2;
    border-color: #348ad2;
}


/* Custom Checkbox */



/* header */

header form input[type=text] {
    width: 636px;
    font-family: 'poppinslight';
    height: 45px;

}

.dashboard_header .dropdown-toggle:active {
    outline: none;
    box-shadow: none;
    background-color: transparent !important;
    border: none;
}

header form .btn_search {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    height: 100%;
    width: 3.57rem;
    border-radius: 0;
    border: none;
    color: #c7c9cc;
    padding: 0;
    outline: none;
}

header form .btn_search:hover {
    border: none;
    background-color: transparent;
}

header form {
    position: relative;
}

header form .fa {
    position: absolute;
    right: 16px;
    top: 36%;
    font-size: 20px;
    color: #c4c4c4;
}

header .top_header {
    padding: 20px 0px 24px 0px;
}

header .menu_header {
    background-color: #077a3b;
    height: 58px;
    display: flex;
    align-items: center;
}

header .menu_header .navbar-text {
    padding: 0;
}

header .menu_header .navbar-text a {
    color: #fff;
    position: relative;
    padding: .9rem 9px;
}

header .navbar {
    padding: 0px;
}

header .navbar li {
    margin-right: 0px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px 6px;
    font-family: 'poppinssemibold';
}

header .navbar-nav li a {
    /*  font-family: poppins_bold;*/
    color: #fff;
    width: 100%;
}

header .login_menu ul li {
    display: inline-block;
    /*font-family: poppins_bold;*/
    position: relative;
}

header .login_menu ul li:nth-child(1) {
    margin-right: 22px;

}

header .navbar-nav .dropdown-menu {
    border-radius: 0px;
    border: none;
}

/* header .dropdown-menu .dropdown-item:nth-child(1){
  border-bottom: 1px solid;
} */
/* header .dropdown-menu .nav_link_drp1:hover{
 
} */
header .dropdown-menu .nav_link_drp1:hover .dropdown-item {
    color: #ffffff;
    text-decoration: none;
    background-color: #077a3b;
}

header .login_menu ul li:nth-child(1):after {
    content: "";
    position: absolute;
    width: 1px;
    height: 20px;
    background-color: #fff6;
    bottom: 0px;
    top: 9px;
    right: -10px;
}

header.navbar-nav .dropdown-menu {
    position: absolute;
    left: -21px;
    top: 44px;
    min-width: 11rem;
}

header.navbar-nav .dropdown-menu .dropdown-item {
    padding: 10px 9px;
    color: rgb(133, 133, 133);
}

/* header */


/* banner-section */

.banner-section .slick-prev {
    left: 15% !important;
    z-index: 999;

}

.banner-section .slick-prev:hover,
.banner-section .slick-prev:focus,
.banner-section .slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background-color: #077a3b82 !important;
}

.banner-section .slick-prev:before {
    content: "\2190" !important;
}

.banner-section .slick-next {
    right: 16% !important;
}

.banner-section .slick-next:before {
    content: "\2192" !important;
}

.banner-section .slick-prev,
.banner-section .slick-next {
    width: 108px !important;
    background-color: #02080899 !important;
    height: 85px !important;
}

.banner-section .slick-slide img {

    height: 600px;
    width: 100%;
    object-fit: cover;
}

.banner-section .slick-prev:before,
.banner-section .slick-next:before {
    font-family: FontAwesome !important;
    font-size: 36px !important;


}

/* banner-section */

/* buy_section */
.buy_section .container {
    background-color: #077a3b;
    padding: 2.28rem 3.64rem;
    color: #fff;
}

.buy_section .buy_content p {
    /* font-family: rubki_light;*/
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.25;
}

.buy_section {

    margin-top: -104px;
    position: relative;
    z-index: 9;
}

.buy_section .buy_content h1 {
    font-size: 35px;
    font-weight: 600;
    position: relative;
    font-family: 'poppinssemibold';
}

.buy_section .buy_content h1 span {
    color: #077a3b;
    width: 274px;
    background-color: #fff;
    height: 61px;
    padding: 5px 11px;
}

.buy_section .buy_link a {
    background-color: white;
    padding: 11px 37px;
    /* width: 172px; */
    /* height: 48px; */
    color: #077a3b;
    font-weight: 800;
    display: inline;
    font-family: 'poppinssemibold';


}

/* buy_section */

/* service_category */

.service_category .service_icon {
    text-align: center;
    padding: 50px 25px;
    border-right: 1px solid #d9d9d9;
    height: 210px;
}

.service_category .service_icon:hover {
    background-color: #2f7dc9;
}

.service_category .service_icon:hover img {
    filter: brightness(2.5);
}

.service_category .service_icon:hover h2 {
    color: rgb(255 255 255);
}

.service_category .service_icon h2 {
    line-height: 21px;
    font-size: 1.14rem;
    color: rgb(1, 1, 1);
    font-family: 'poppinssemibold';
    margin-bottom: 5px;
}

.service_category .active {
    background-color: #0272be;
}

.service_category .active h2 {
    color: rgb(255 255 255);
}

.service_category .service_icon:nth-child(3) {
    background-color: #0272be;
    color: #fff;
}

.service_category .slick-slide img {
    display: inline-block;
}

.service_category .slick-list {
    border: 1px solid #d9d9d9;

}

.service_category .slick-prev,
.service_category .slick-next {
    width: 34px !important;
    height: 34px !important;
    border: 1px solid #d9d9d9;
}

.service_category .slick-prev {
    left: -50px;
}

.service_category {
    padding: 3.5rem 0rem;
}


.service_category .slick-next {
    right: -49px;
}

.service_category .slick-prev:hover,
.service_category .slick-prev:focus,
.service_category .slick-next:hover,
.slick-next:focus {
    outline: none;
    background-color: #077a3b !important;
}

.service_category .slick-prev:hover:before,
.service_category .slick-next:hover:before {
    color: #fff;
}


.service_category .slick-prev:before,
.service_category .slick-next:before {
    font-family: FontAwesome !important;
    font-size: 19px !important;
    color: #787878;
    opacity: 1;
}

/* service_category */

/* top_product_sec */

.top_product_sec {
    background-color: #f5f5f5;
    padding: 50px 0px;
}

.top_product_sec .slick-slider {
    margin: 0px 5px;
}

.product_content .product_inner_content {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 1px 3px 0px rgba(27, 23, 26, 0.2);
    margin: 0px 6px;
}

.top_product_sec .product_content .product_content_cn {
    padding: 18px 17px;
    min-height: 162px;
    position: relative;
    padding-bottom: 63px;
}

.top_product_sec .product_content .product_content_cn .btn-change7 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 30px);
    margin: 0 20px 15px 15px;
}

.product_content img {
    min-height: 210px;
    width: 100%;
}

.top_product_sec .product_content .product_content_cn h3 {
    font-size: 1.14rem;
    color: rgb(1, 1, 1);
    font-family: 'poppinssemibold';
    margin-bottom: 5px;
}

.top_product_sec .product_content .product_content_cn p {
    font-size: 14px;
    color: rgb(152, 152, 152);
    line-height: 1.429;
    font-family: 'poppinslight';
}

.top_product_sec .product_content .product_content_cn a {
    text-align: center;
    border: 1px solid #0272be;
    padding: 7px 0px;
    color: #0272be;
    font-family: 'poppinsmedium';
}

.inner_heading h2 {
    font-size: 1.57rem;
    color: rgb(0, 0, 0);
    font-family: 'poppinssemibold';

}

.inner_heading a {
    font-size: 1rem;
    color: rgb(140, 140, 140);
    line-height: 1.714;
}

.top_product_sec .slick-prev,
.top_product_sec .slick-next {
    width: 34px !important;
    background-color: #fff !important;
    height: 34px !important;
    border: 1px solid #d9d9d9;
}

.top_product_sec .slick-prev {
    left: 93%;
    top: -6%;
}

.top_product_sec .slick-next {
    right: 0%;
    top: -6%;
}

.top_product_sec .slick-prev:hover,
.top_product_sec .slick-prev:focus,
.top_product_sec .slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background-color: #077a3b !important;
}

.top_product_sec .slick-prev:hover:before,
.top_product_sec .slick-next:hover:before {
    color: #fff;
}

.top_product_sec .slick-prev:before,
.top_product_sec .slick-next:before {
    font-family: FontAwesome !important;
    font-size: 23px !important;
    color: #787878;
    opacity: 1;
}

/* top_product_sec */

.btn-change7 {
    box-shadow: 0 0 1px #ccc;
    -webkit-transition-duration: 0.5s !important;
    -webkit-transition-timing-function: linear !important;
    box-shadow: 0px 0 0 #0272be inset !important;
    width: 100%;
}

.btn-change7:hover {
    box-shadow: 450px 0 0 #0272be inset !important;
    color: #fff !important;
}

.btn-change8 {
    box-shadow: 0 0 1px #ccc;
    -webkit-transition-duration: 0.5s !important;
    -webkit-transition-timing-function: linear !important;
    box-shadow: 0px 0 0 #fff inset !important;
    width: 100%;
}

.btn-change8:hover {
    box-shadow: 450px 0 0 #000 inset !important;
    color: rgb(255 255 255) !important;
    border: 1px solid #fff;
}

/* partner_logo */

.partner_logo {
    padding: 7.5rem 0rem;
    background-image: url(../img/partner_background.jpg);
    background-size: cover;
}

.partner_logo .slick-slide img {
    margin: 0 auto;
}


/* partner_logo */

/* contact_us */

.faq .contact_form {
    background-image: url(../img/map.png);
    background-size: cover;
    padding: 25px 32px 43px 33px;
    border-radius: 0px;
    margin-left: 31px;
}

.faq .contact_form h4 {
    font-size: 20px;
    color: rgb(67, 67, 67);
    line-height: 2.55;
    font-family: 'poppinsmedium';
}

.faq .contact_form input[type=text],
input[type=email] {
    height: 57px;
    border: 1px solid rgb(209, 209, 209);
    font-family: 'poppinslight';
}

.faq .contact_form textarea {
    height: 127px;
    border: 1px solid rgb(209, 209, 209);
}

.faq .contact_form .btn_submit {
    background-color: #0081c6;
    color: #fff;
    font-family: 'poppinsmedium';
    display: inline-block;
    width: 100%;
    height: 56px;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    border-radius: 0px;
    padding: 0;
}

.contact_heading h2 {
    text-align: center;
    font-family: 'poppinssemibold';
    font-size: 2.14rem;
    color: rgb(67, 67, 67);
}

.faq {
    padding: 3.5rem 0rem;
}

.faq .contact_info h3 {
    /*  font-family: lato_regular_semi_bold;*/
    font-size: 1.57rem;
    color: rgb(67, 67, 67);
}

.faq .contact_info h4 {
    font-size: 1.28rem;
    font-family: 'Lato';
    color: rgb(67, 67, 67);
    line-height: 2.833;
}

.faq .contact_info p {
    font-size: 1rem;
    font-family: 'poppinslight';
    color: rgb(151, 151, 151);
    line-height: 1.786;

}

.faq .contact_info ul li a {
    font-size: 1rem;
    color: rgb(151, 151, 151);
    line-height: 1.786;
}

.faq .contact_info ul li a:hover {
    color: #0081c6;
}

.faq .contact_form span {
    font-size: 14px;
    color: rgb(112, 112, 112);
    margin-bottom: 1.78rem;
    display: block;
    font-family: 'poppinslight';
}

.faq .contact_form span a {
    color: #707070;
    font-weight: 800;
}

/* contact_us */

/* equipment_sec */

.equipment_sec {
    background-color: #fff;
}

/* equipment_sec */
/* footer */

/* footer */

footer {
    padding-top: 3.57rem;
    background-color: #272727;

    width: 100%;
    color: #fff;

}



footer .footer-content p {

    font-size: 1.21rem;
    color: rgba(255, 255, 255, 0.702);
    line-height: 1.518;
    margin-top: 1.3rem;
    font-family: 'poppinslight';
    font-size: 1rem;
}

.link-heading {
    font-size: 1.30rem;
    color: #fff;
    text-transform: uppercase;
    line-height: 2.189;
    font-family: 'poppinssemibold';
}

footer .quikc-heading ul li a {
    font-size: 15 px;
    color: rgba(255, 255, 255, 0.702);
    line-height: 1.573;
}

footer .quikc-heading ul li a:hover {
    color: white;
}

footer .social-footer li a:hover {
    background-color: #fff;
    color: #077a3b;
}

footer .social-footer li a:hover .fab {
    color: #077a3b;
}

footer .social-footer li {
    display: inline-block;
    margin-right: 7px;
}

footer .social-footer li a {
    color: #fff;
    border: 1px solid #fff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer .social-footer {
    margin-top: 5px;
}

footer .copyright p {

    margin: 0;
    color: #4e4e4e;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.302);
}


footer .copyright {
    border-top: 1px solid #3a3737;
    padding: 13px 0px;
    margin-top: 4rem;
    background-color: #373737;
}


/* footer */

/* hover-menu */





/* layout */

/* nav */
/* nav{ height:50px; line-height:50px;}
nav a{position:relative; color:#fff;  } */


/* 
.btn-8 a:before,
.btn-8 a:after{content:""; display:block;} */

/* btn-8 */
/* .btn-8{overflow: hidden;}
.btn-8 a{z-index:0}
.btn-8 a:before{position:absolute;
   left:0; bottom:100%; width:100%;
    height:100%; background-color:#a94646;
     z-index:-1; opacity:0; -webkit-transition:all .2s ease-out; 
     -o-transition:all .2s ease-out; transition:all .2s ease-out; 
     border-bottom:2px solid #ffe800;}
.btn-8 a:hover:before{opacity:1; bottom:0;} */

/* Pull up  */
header .navbar ul li .nav_link_hover:before {
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    bottom: 0px;
    content: '';
    background: #FFF;
    opacity: 0.3;
    transition: all 0.3s;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -12px;
    padding: 0px;
    top: 48px;
}

header .dropdown-item {
    padding: 10px 13px;
    font-family: 'poppinsregular';
}

header .dropdown-divider {
    margin: 0;
}

header .navbar ul li .nav_link_hover:hover:before {
    height: 100%;
}

/* hover-menu */

/* login_signup_sec */

.login_signup_sec .login_signup_form {
    box-shadow: 0px 0px 40.94px 5.06px rgb(197 197 197 / 20%);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(199, 206, 224);
    max-width: 750px;
    margin: 0 auto;
    display: block;
    padding: 34px 38px;
    border-radius: 5px;
    width: 100%;
}

.login_signup_sec .login_signup_form .form-group {
    margin: 0;
}

.login_signup_sec .login_signup_form label {
    font-size: 13px;
    font-family: 'poppinslight';
    color: rgb(169, 175, 182);
    line-height: 1.846;
}

.login_signup_sec .login_signup_form label a {
    font-family: 'poppinsregular';
}

.login_signup_sec .login_signup_form .form-control {
    border: 1px solid rgb(206, 212, 229);
    border-radius: 5px;
    height: 51px;
}

.login_signup_sec .login_signup_form .login_text span {
    font-family: 'poppinssemibold';
}

.login_signup_sec .login_signup_form .already_account p {
    font-size: 1rem;
    color: rgb(147, 152, 158);
    font-family: 'poppinslight';
}

.same_color_term {
    color: #0272be !important;
    font-weight: 700;
}

.login_signup_sec .login_signup_form .form_logo img {
    max-width: 141px;
    max-height: 38px;
}

.login_signup_sec .login_signup_block .login_text {
    font-size: 1rem;
    color: rgb(29, 29, 29);
    font-weight: 600;
}

/* .login_signup_sec .login_signup_form input[type=submit]
{

} */


/* checkbox */
.main {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 10px
}

.term_condition_checkbox span:before,
.term_condition_checkbox span:after {
    content: "";
    display: inline-block;
    background: #fff;
    width: 0;
    height: 0.2rem;
    position: absolute;
    transform-origin: 0% 0%;
}

.term_condition_checkbox {
    position: relative;
    height: 2rem;
    display: flex;
    align-items: center;
}

.term_condition_checkbox input {
    display: none;
}

.term_condition_checkbox input:checked~span {
    background: #fd2727;
    border-color: #fd2727;
}

.term_condition_checkbox input:checked~span:before {
    width: 1rem;
    height: 0.15rem;
    transition: width 0.1s;
    transition-delay: 0.3s;
}

.term_condition_checkbox input:checked~span:after {
    width: 0.4rem;
    height: 0.15rem;
    transition: width 0.1s;
    transition-delay: 0.2s;
}


.term_condition_checkbox label {
    padding-left: 3rem;
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-bottom: 0;

}

.term_condition_checkbox span {
    display: inline-block;
    /* width: 1.2rem; */
    /* height: 1.2rem; */
    border: 1px solid #ccc;
    position: absolute;
    left: 0;
    transition: all 0.2s;
    z-index: 1;
    box-sizing: content-box;
    border-radius: 5px;
    width: 27px;
    height: 23px;
}

.term_condition_checkbox span:before {
    transform: rotate(-55deg);
    left: 9px;
    top: 16px;
}


.term_condition_checkbox .forget_password a {
    font-family: 'poppinssemibold';
    font-size: 1rem;
    color: rgb(2, 114, 190);
}

.term_condition_checkbox span:after {
    transform: rotate(35deg);
    left: 6px;
    bottom: 7px;

}


/* checkbox */
/* login_signup_sec */

/* about_sec */

.about_sec {
    padding: 3.5rem 0rem;
}

.about_sec .about_sec_com h2 {
    font-size: 1.78rem;
    font-family: 'poppinsbold';
    color: rgb(30, 40, 53);
    line-height: 0.96;
    margin-bottom: 10px;
}

.about_sec .about_sec_com p {
    font-size: 1rem;
    font-family: 'poppinslight';
    color: rgb(114, 114, 114);
    line-height: 1.429;
}

/* about_sec */


/* our_team_sec */

.our_team_sec {
    padding: 3.5rem 0rem;
    background-color: #077a3b;
    color: #fff;
    height: 338px;
}




.our_team_sec .team_heading_sec h2 {
    font-size: 1.40rem;
    color: #fff;
    margin-bottom: 11px;
    font-family: 'poppinssemibold';
}


.our_team_sec .team_heading_sec {
    width: 710px;
    margin: 16px auto;
    display: block;
}

.our_team_sec .team_heading_sec p {
    font-family: 'poppinslight';
    line-height: 1.305;
    color: rgb(255 255 255 / 85%);
    font-size: 1rem;
}

.team_photo_sec .slick-slide img {
    width: 100%;
}

/* circle */
.circle {
    height: 56px;
    width: 56px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 19px 0px rgba(0, 0, 0, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    left: 47%;
    -webkit-transition: height .25s ease, width .25s ease;
    transition: height .25s ease, width .25s ease;
    transform: translate(0%, -142%);
    position: absolute;
    top: 0px;
}

.circle .fa {
    color: #51be59;
    font-size: 21px;
}


.circle:before,
.circle:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid rgb(255 255 255 / 29%);
}

.circle:before {
    -webkit-animation: ripple 2s linear infinite;
    animation: ripple 2s linear infinite;
}

.circle:after {
    -webkit-animation: ripple 2s linear 1s infinite;
    animation: ripple 2s linear 1s infinite;
}



@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(1);
    }

    75% {
        -webkit-transform: scale(1.75);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        opacity: 0;
    }
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.75);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

/* circle */
/* our_team_sec */

/* team_photo_sec */
.team_photo_sec {
    margin-top: -154px;
    padding-bottom: 65px;
}

.db_team img {
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

.db_team>div {
    overflow: hidden;
}

.db_team>div:hover img {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
}

/* team_photo_sec */

/* marketplace_sec */

.marketplace_sec {
    padding: 3.5rem 0rem;
    background-color: #f5f5f5;
    text-align: center;

}

.marketplace_sec .marketplace_inner_col h2 {
    font-size: 24px;
    color: rgb(7, 122, 59);
    font-family: 'poppinssemibold';
    line-height: 1.333;
    margin-bottom: 6px;
}

.marketplace_sec .marketplace_inner_col h4 {
    color: #434343bf;
    font-size: 17px;
    font-family: 'poppinsregular';
}

/* marketplace_sec */


/* story_sec */
.about_company {
    padding-top: 3rem !important;
    padding-bottom: 0px;
}

.contact_faq {
    padding-top: 0px;
}

.story_sec {
    padding: 5rem 0rem;
}

.story_sec .story_inner h2 {
    font-size: 2.14rem;
    font-family: 'poppinssemibold';
    color: rgb(67, 67, 67);
    line-height: 1.7;
}

.story_sec .story_inner p {
    font-size: 1rem;
    font-family: 'poppinslight';
    color: rgb(151, 151, 151);
    line-height: 1.786;
}

/* story_sec */


/* enquiry_form */

.enquiry_form {
    padding: 3rem 0px;
}

.enquiry_form .enquiry_form_inner {
    background-color: #fafafa;
    padding: 2.85rem;
    width: 500px;
    border: 1px solid rgb(235, 235, 235);
    padding: 2.21rem 2.64rem 2.78rem 2.78rem;
    margin: 0 auto;
    display: block;
}

.enquiry_form .enquiry_form_inner h2 {
    font-size: 1.35rem;
    color: rgb(60, 60, 60);
    font-family: 'poppinssemibold';
    line-height: 1.55;
}

.enquiry_form .enquiry_form_inner h4 {
    font-size: 1.21rem;
    color: rgb(60, 60, 60);
    line-height: 1.722;
    font-weight: 700;
}

.enquiry_form .enquiry_form_inner h5 {
    font-size: 1.14rem;
    color: rgb(1, 1, 1);
    font-family: 'poppinssemibold';
    line-height: 1.498;
}

.enquiry_form .enquiry_form_inner span {
    font-size: 0.71rem;
    color: rgb(125, 125, 125);
    text-transform: uppercase;
    line-height: 2.397;
}

.enquiry_form .enquiry_form_inner form input[type=text] {
    width: 79px;
    height: 34px;
    border-radius: 1px;
    border: 1px solid rgb(211, 211, 211);
    background-color: rgb(249, 249, 249);
    font-size: 1rem;
    padding-left: 8px;
    color: rgb(125, 125, 125);
}

.enquiry_form .enquiry_form_inner form p {
    font-size: 1rem;
    font-family: 'poppinslight';
    color: rgb(162, 162, 162);
    line-height: 1.571;
    width: 97%;
}

.enquiry_form .btn {
    text-transform: uppercase;
    font-family: 'poppinssemibold';
}

/* enquiry_form */

/* order_detail_sec */

.order_detail_sec {
    padding: 6px 0px 51px 0px;

}

/* .order_detail_sec .order_detail_inner {

} */


.order_detail_sec .order_detail_inner h2 {
    font-size: 1.64rem;
    font-family: 'poppinssemibold';
    color: rgb(60, 60, 60);
    line-height: 1.292;

}

.order_detail_sec .order_detail_inner .n {
    font-size: 1rem;
    color: rgb(162, 162, 162);
    line-height: 2.28;
    font-family: 'poppinslight';
}

.order_detail_sec .order_detail_inner ul li {
    display: inline-flex;
    font-family: 'poppinslight';
    margin-right: 16px;
    font-size: 1rem;
    color: rgb(162, 162, 162);
    line-height: 2.28;
    position: relative;
    align-items: center;
}

.order_detail_sec .order_detail_inner .price {
    font-size: 1.14rem;
    color: rgb(1, 1, 1);
    font-family: 'poppinssemibold';
    line-height: 1.498;
}

.descpt {
    font-size: 15.301px;
    color: rgb(60 60 60 / 79%);
    line-height: 1.938;
    font-family: 'poppinssemibold';

}

.order_detail_sec .order_detail_inner .desc p {
    font-size: 1rem;
    color: rgb(162, 162, 162);
    line-height: 1.571;
}

.order_detail_sec .social_des ul li {
    border: 2px solid #ad8b8b4d;
    border-radius: 3px;
    padding: 0px 8px;
    height: 36px;
    width: auto;
}

.social_des ul {
    margin-bottom: 15px;
}

.order_detail_sec .social_des ul li:hover:nth-child(2) {
    background-color: #4c6fa8;
    color: #fff;
}

.order_detail_sec .social_des ul li:hover:nth-child(3) {
    background-color: #e50213;
    color: #fff;
}

.order_detail_sec .social_des ul li:hover:nth-child(4) {
    background-color: #1ea1f3;
    color: #fff;
}

.order_detail_sec .social_des ul li:hover:nth-child(5) {
    background-color: #ef5e4e;
    color: #fff;
}

.history-image img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
}


.listing-img img {
    width: 100%;
    height: 250px;
    display: inline-block;
    object-fit: cover;
}

.my_profile_form label {
    font-size: 13px;
    font-family: 'poppinslight';
    color: rgb(169, 175, 182);
    line-height: 1.846;
}


.list_wrapper p {
    width: 100%;
    word-break: break-word;
}


.order_detail_sec .social_des ul li:hover a {
    color: #fff;
}

.order_detail_sec .social_des .btn {
    font-family: 'poppinsmedium';
}

.order_detail_sec .social_des ul li {
    margin-right: 5px;
}

.order_detail_sec .social_des ul li a span {
    display: inline-block;
    margin-right: 8px;
}

.order_detail_sec .social_des ul li a {
    font-size: 11px;
    color: #000000b5;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    /* display: inline-block; */
    object-fit: cover;
    display: inline-block;
    height: 100px;
    line-height: 0;
}

.order_detail_sec .social_des ul li a .fa-facebook-f {
    background-color: #4c6fa8;

}

.order_detail_sec .social_des ul li a .fa-pinterest {
    background-color: #e50213;

}

.order_detail_sec .social_des ul li a .fa-twitter {
    background-color: #1ea1f3;

}

.order_detail_sec .social_des ul li a .fa-envelope {
    background-color: #ef5e4e;

}

.order_detail_sec .social_des ul li a .icon {
    color: #fff;
    width: 22px;
    height: 22px;
    min-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}


/* order_detail_sec */

/* bread_sec */

.bread_sec .bread_sec_inner ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 23px;
    position: relative;
}

.bread_sec .bread_sec_inner ul li a {
    position: relative;
}

/* .order_det ul li:nth-child(odd):after
{
  position: absolute;
  top: 8px;
  width: 1px;
  height: 15px;
  background-color: #00000059;
  content: "";
  left: 109%;
} */

.bread_sec .bread_sec_inner ul li:nth-child(1) a:after {
    position: absolute;
    top: 12px;
    width: 1px;
    height: 15px;
    background-color: #00000059;
    content: "";
    left: 110%;
}

.bread_sec {
    padding-top: 22px;
}

.bread_sec ul li a {

    font-size: 1rem;
    color: rgb(148, 148, 148);
    line-height: 2.789;
}


/* bread_sec */


/* gallery-producgt */


.product_gallery .image-gallery-play-button,
.image-gallery-fullscreen-button {
    display: none;
}

.product_gallery .image-gallery-content .image-gallery-slide .image-gallery-image {
    /* width: 646px; */
    height: 499px;
    /* object-fit: cover; */
}

.product_gallery .image-gallery-left-nav,
.product_gallery .image-gallery-right-nav {
    padding: 0;
    width: 51px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.40);
    filter: none;
}

.product_gallery .image-gallery-left-nav:after,
.product_gallery .image-gallery-right-nav:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Font Awesome 5 free";
    font-weight: 900;
}

.product_gallery .image-gallery-left-nav:after {
    content: "\f060";
}

.product_gallery .image-gallery-right-nav:after {
    content: "\f061";
}

.product_gallery .image-gallery-left-nav {
    left: 2rem;
}

.product_gallery .image-gallery-right-nav {
    right: 2rem;
}

.order_detail_inner .btn_submit {
    margin-top: 2.07rem;
    ;
}

.product_gallery .image-gallery-left-nav svg,
.product_gallery .image-gallery-right-nav svg {
    display: none;
}

.product_gallery .image-gallery-thumbnail {
    width: 16.66%;
}



.product_gallery .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 52px;
    width: 52px;
    background-color: #0000006e;
    outline: none;
}

.product_gallery .image-gallery-icon:hover {
    color: #ffffff;
}




/**faq**/

.faq_heading .title {
    color: #1e2835;
    /*font-family: poppins_bold;*/
    font-family: 'poppinsbold';
    font-size: 1.78rem;
    line-height: 2;
}

.faq_heading p {
    color: #474747;
    font-size: 1rem;
    font-family: 'poppinslight';
}

.accordion__item p {
    line-height: 1.5rem;
}

.accordion__item {
    box-shadow: 0px 0px 2px 0px rgba(82, 82, 91, 0.60);
    margin-bottom: 1rem;
    padding: 1.5rem;
}

.accordion__button {
    display: flex;
    align-items: center;
}

.accordion__button:after {
    margin-left: auto;
    content: "\f0d7";
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    font-size: 1.3rem;
    color: #7c7c7c;
}

.accordion__button:focus {
    outline: none;
}

.accordion__heading {
    color: #474747;
    font-size: 1rem;
}

.accordion__panel {
    font-size: 1rem;
    color: #a4a4a4;
    padding-top: 1rem;
}




/* gallery-product */
@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}


/* dashbaord */

/* notification */

.main_notification1 .notification_name .notify_user .name_user span {
    color: #007f3a;
    border: 1px solid #007f3a;
    border-radius: 50px;
    font-size: 12px;
    width: 44px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center
}

.main_notification1 {
    box-shadow: 0px 2px 68px 0px rgba(197, 195, 195, 0.15);
    border: 1px solid #0000001a !important;
}

.main_notification1 .new_notifcation {
    padding: 0.85rem 1.21rem !important;
    border-bottom: 1px solid #0000001f !important;
    border-radius: 6px 6px 0px 0px;

}

.main_notification1 .new_notifcation:nth-child(1) {
    background-color: #fff;
}

.main_notification1 .new_notifcation:nth-child(2) {
    background-color: #fff;
}

.main_notification1 .notification_name .notify_user .time h3 {
    font-size: 12px;
    color: rgb(99, 99, 99);
    line-height: 1.4;
}

.main_notification1 .notification_name p {

    font-size: 1rem;
    color: rgb(153, 153, 153);
    line-height: 1.4;

}


/* notification */

/* profile_details */

.dashboard_inner .profile_details {
    width: 100% !important;
}

.dashboard_inner .profile_details .form-control {
    height: 3.85rem;
    font-size: 1rem;
    color: #b7b7b7;
    font-weight: 300;
    border-radius: 0px;
    border: 1px solid #e6e6e6;
    padding: 0.8rem 1.4rem;
    margin-bottom: 8px;
    border-radius: 5px;
    font-family: 'poppinslight';
    background-color: transparent !important;
}

.dashboard_inner .change_password .cancel_button ul li:nth-child(2) a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 161px;
    height: 43px;
}

/* profile_details */


/* dashbaord */

.modal-footer {
    border: none;
}

.modal-header {
    border-bottom: none;
}

/* .close{
  opacity: 1;
  color: #fff;
} */


/* product_model */
.modal-content {
    border-radius: 0px;
    padding: 1.07rem 1.5rem 4.92rem 1.5rem;
    width: 37.7rem;
    height: 32.5rem;
}

.modal-header .close span {
    width: 30px;
    height: 30px;
    min-height: 30px;
    background-color: black;
    display: block;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.tab-content {
    padding-top: 1.71rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #0272be;
}

.nav-tabs .nav-link {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #373737;
    width: 6.78rem;
    height: 2.71rem;
    border-radius: 4px;
}

.tab_content_inner h2 {
    color: #5f5f5f;
    font-family: 'poppinsmedium';
    font-size: 1.14rem;

}

.product_detail_popup .form-control {
    height: 47px;
    border-radius: 5px;
    font-size: 14px;
    color: rgb(190, 194, 204);
}

.product_detail_popup .react-datepicker-wrapper {
    display: initial;
}

.react-datepicker__input-container::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f073 ";
    position: absolute;
    right: 18px;
    top: 15px;
    color: #b4b8c4;
}

/* listing*/


.btn-filter {
    background-color: #077a3b;
    border-color: #077a3b;
}

.Search_sec {
    background-color: #fbfbfb;
    border: 1px solid #e3e6ef;
    padding: 1.85rem 2.28rem;
}

.Search_sec .Search_field {
    position: relative;
}

.Search_sec .btn_search {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    height: 100%;
    width: 3.57rem;
    border-radius: 0;
    border: none;
    color: #c7c9cc;
    padding: 0;
}

.Search_sec .form-control {
    height: 3rem;
    border: 1px solid #e1e4ed;
    border-radius: 0;
    color: #b8babe;
    font-size: 1rem;
}

.Search_sec .form-control::-webkit-input-placeholder {
    color: #b8babe;
}

.Search_sec .form-control::-moz-placeholder {
    color: #b8babe;
}

.Search_sec .form-control:-ms-input-placeholder {
    color: #b8babe;
}

.Search_sec .form-control:-moz-placeholder {
    color: #b8babe;
}

.Search_sec .gird {
    background-color: #fff;
    border: 1px solid #e3e6ef;
    color: #b8babe;
    font-size: 1.40rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.80rem;
}


.list_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0rem -0.66rem;
}

.box_list {
    position: relative;
    box-shadow: 0px 1px 3px 0px rgba(27, 23, 26, 0.2);
    width: 32%;
    margin: 0rem 0.33rem 1.14rem;
}

.box_list .name {
    color: #010101;
    font-size: 1.14rem;
    font-family: 'poppinssemibold';
    margin-bottom: 5px;
}

.box_list .price {
    font-size: 1rem;
    color: #010101;
    font-family: 'poppinssemibold';
    margin: 7px 0rem;
}

.box_list p {
    font-size: 1rem;
    color: #989898;
    font-family: 'poppinslight';
    line-height: 1.6;
}

.box_list .contact {
    margin: 5px -0.5rem;
}

.box_list .contact li {
    border-right: 1px solid #b6b6b6;
    color: #b0b0b0;
    font-size: 0.92rem;
    padding: 0 0.5rem;
    font-family: 'poppinslight';
}

.box_list .contact li:last-child {
    border: none;
}

.box_list .view_link {
    color: #0272be;
    font-size: 1.07rem;
    font-family: 'poppinsmedium';
    border: 1px solid #0272be;
    text-align: center;
    padding: 0.65rem;
}

.box_list .view_link:hover {
    background-color: #0272be;
    color: #fff;
}

.box_list .rent_tag {
    background-color: #38ae43;
    padding: 0.35rem 1rem;
    font-size: 1rem;
    color: #ffffff;
    font-family: 'poppinsmedium';
    position: absolute;
    ;
    right: 0;
    top: 1rem;
}

.bg_grey {
    background-color: #fbfbfb;
    box-shadow: none;
    border: 1px solid #c7cee0;
}

.content {
    width: calc(100% - 35%);
}

.email_box p {
    font-size: 0.78rem;
    color: #989898;
}

.email_box p a {
    color: #0272be;
}

.email_box .link {
    font-size: 1rem;
    background-color: #0272be;
    color: #fff !important;
    font-family: 'poppinsmedium';
    height: 2.92rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 15.5rem;
}

.sidebar .form-control {
    height: 2.57em;
}

.sidebar_heading {
    border-bottom: 1px solid #eaeaea;
}

.sidebar {
    border: 1px solid #eaeaea;
}

.sidebar_heading h3 {
    color: #010101;
    font-size: 1rem;
    font-family: 'poppinsmedium';
}

.sidebar_heading .link {
    color: #0272be;
    font-size: 0.85rem;
    font-family: 'poppinslight';
}

.sidebar .seprator {
    padding: 12px;
}

.sidebar .seprator+.seprator {
    border-top: 1px solid #eaeaea;
}

.sidebar .seprator h6 {
    color: #222222;
    font-size: 0.92rem;
    font-family: 'poppinsmedium';
    margin-bottom: 5px;
}

.sidebar .seprator li+li {
    margin-top: 8px;
}


.custom_check {
    color: #898989;
    font-size: 0.85rem;
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 22px;
    padding-left: 30px;
    font-family: 'poppinslight';
}

.custom_check input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin: 0px 0px;
    z-index: 2;
}

.custom_check .check_indicator {
    height: 22px;
    width: 23px;
    position: absolute;
    top: 3px;
    left: 0px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
    width: 5px;
    height: 10px;
    position: absolute;
    left: 9px;
    top: 4px;
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
}

.custom_check input:checked+.check_indicator {
    background-color: #0078ff;
}


.sidebar .btn {
    color: #898989;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'poppinslight';
}

.sidebar .btn:hover {
    text-decoration: none;
}

.sidebar .btn:after {
    margin-left: auto;
    content: "\f107";
    font-family: "Font Awesome 5 free";
    font-weight: 900;
}

/* listing */
.sidebar_menu li a {
    color: #898989;
}

.sidebar_menu li {
    font-size: 13px;
    color: #898989;
    padding: 3px 0;
}




/*Dashboard*/

.container-fluid {
    padding: 0 3.07rem;
}


.dashboard_header {
    background-color: rgb(255, 255, 255);
    padding: 1rem 0;
    z-index: 9;
    position: fixed;
    width: 100%;
    top: 0;
    border-bottom: 2px solid #ebebeb;
}

.dash_logo img {
    width: 14.28rem;
}

.DashHeader_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.DashHeader_right .user {
    display: flex;
    align-items: center;
    color: #ef4343;
    font-size: 1.23rem;
}

.DashHeader_right .user img {
    width: 3.42rem;
    height: 3.42rem;
    border-radius: 100%;
}


.DashHeader_right .dropdown-toggle {
    background-color: transparent;
    color: #939393;
    border: none;
    padding: 0 0 0 0.7rem;
    font-family: 'poppinsregular';
    font-size: 1rem;
    display: flex;
}

.DashHeader_right .dropdown-toggle:after {
    content: "\f078";
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    display: inline-block;
    margin-left: 0.7rem;
    border: 0;
}

.DashHeader_right .dropdown-menu {
    min-width: 12rem;
    position: absolute;
    background-color: #fff;
    left: inherit;
    right: 0px;
    border: 0px;
    border-radius: 0px 0px 3px 3px;
    top: 100% !important;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    padding: 10px 0px;
    margin: 0px 0px;
}

.DashHeader_right .dropdown-menu:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    top: -8px;
    right: 1rem;
    content: '';
}

.DashHeader_right .dropdown-menu a {
    font-size: 1rem;
    color: #757575;
    display: block;
    line-height: normal;
    padding: 0.6rem 1rem;
    margin: 0px 0px;
}

.DashHeader_right .dropdown-menu a:hover {
    color: #007f3a;
}

.DashHeader_right .dropdown-menu a.green {
    color: #007f3a;
}

.DashHeader_right .show>.btn-success.dropdown-toggle {
    background-color: #fff;
    color: #939393;
}

.dashboard_outer {
    padding-top: 84px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    background: #f9f9f9;
}

.dashboard_sidebar {
    width: 300px;
    height: calc(100% - 30px);
    background: #007f3a;
    padding: 60px 0;
    position: fixed;
    bottom: 0;
    top: 84px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 3px 0px 46px 0px rgba(172, 172, 172, 0.6);
}



.dashboard_inner {
    width: calc(100% - 300px);
    padding: 2.30rem 3.46rem;
    margin-left: 299px;
    min-height: 100vh;
}

.dashboard_inner .dash_main_inner {
    padding: 22px 23px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
}

.discussion_notify {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0.78rem 2.14rem 0.78rem 0.78rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 68px 0px rgba(197, 195, 195, 0.15);
    margin-top: 0.85rem;
}

.discussion_notify .alternate_image {
    width: 7.28rem;
    height: 6.92rem;
}

.discussion_notify .lerory h2 {
    font-family: 'poppinsmedium';
    color: #353842;
    font-size: 1.28rem;
}

.discussion_notify .lerory p {
    font-size: 0.92rem;
    color: #939393;
    font-family: 'poppinslight';
}

.discussion_notify .lerory span {
    font-family: 'poppinsmedium';
    font-size: 1rem;
}

.discussion_notify .lerory .price {
    font-size: 1.07rem;
    font-family: 'poppinsmedium';
}

.discussion_notify .btn_discount {
    background-color: #0272be;
    color: #fff;
    padding: 0.5rem 1.14rem;
    border-radius: 30px;
    font-size: 0.78rem;
    margin-top: 1rem;
}

.discussion_notify .lerory {
    width: calc(100% - 15rem);
}

.discussion_notify .box_discount {
    width: 10rem;
    text-align: right;
}

.pagination_deshbaord {
    padding: 2.5rem 0rem;
}

.pagination_deshbaord ul {
    display: flex;
    align-items: center;
}

.pagination_deshbaord li {
    padding: 0 0.4rem;
}

.pagination_deshbaord li a {
    font-size: 1.07rem;
    color: #3d3d3d;
}

.sidebar_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    border: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: 0.4s ease all;
    visibility: hidden;
}

.filter_div .Search_sec {
    min-width: 15rem;
}

.filter_div .Search_sec .form-control {
    height: 2.66rem;
    font-size: 0.85rem;
    font-family: 'poppinslight';
    border-radius: 3px;
}

.filter_div .Search_sec .btn_search {
    font-size: 0.95rem;
}

.filter_div .btn_filter {
    background-color: #fdfdfd;
    border: 1px solid #e6e6e6;
    height: 100%;
    border-radius: 3px;
    width: 80px;
    font-size: 1rem;
    color: #b8babe;
    font-family: 'poppinslight';
}



.dashboard_inner .change_password {
    width: 565px;

}

.dashboard_inner .change_password .cancel_button ul li {

    display: inline-block;

}

.dashboard_inner .change_password .cancel_button ul li a {
    padding: 17px 20px;
    border-radius: 2px;

}

.dashboard_inner .change_password .cancel_button ul li:nth-child(2) a {

    background-color: #0272be;
    color: #fff;
}

.dashboard_inner .change_password .cancel_button ul li:nth-child(1) a {
    color: #454545;
}

.dashboard_inner .change_password input[type=password] {
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 8px;
}

.dashboard_profile {
    position: relative;
}


.profile_userimg span {
    display: block;
    position: absolute;
    right: 0.5rem;
    bottom: 1rem;
    height: 2.14rem;
    width: 2.14rem;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #007f3a;
}


.profile_userimg .fa {
    color: #fff;
    z-index: 1;
}

.profile_userimg .custom-file {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
}

.profile_userimg {
    width: 9.57rem;
    height: 9.57rem;
    margin: 0 auto 15px;
    display: block;
    position: relative;
}

.profile_userimg figure {
    overflow: hidden;
    object-fit: cover;
    border: 5px solid rgba(255, 255, 255, 0.17);
    border-radius: 100%;
    width: 100%;
    height: 100%;
}


.dashboard_profile h5 {
    color: #fff;
    font-size: 1.35rem;
    font-family: 'poppinssemibold';

}

.dashboard_menu ul li a {
    color: #fff;
    font-size: 1.07rem;
    display: flex;
    align-items: center;
    padding: 15px 1.53rem;
}

.dashboard_menu ul li a span {
    margin-right: 1rem;

}




.dashboard_menu ul li.active a,
.dashboard_menu ul li a:hover {
    background: #fff;
    color: #007f3a;
}

.dashboard_menu ul li a span .hover {
    display: none;
}

/* .dashboard_menu ul li.active a .hover, .dashboard_menu ul li a:hover .hover{ display:block;} */

.dashboard_menu ul li a:hover .hover,
.dashboard_menu ul li.active a .hover {
    display: block;
}

.dashboard_menu ul li a:hover img,
.dashboard_menu ul li.active a img {
    display: none;
}

.dashboard_menu ul li.active a span,
.dashboard_menu ul li a:hover span {
    opacity: 1;
}

.dash_heading {
    margin-bottom: 12px;
    font-size: 1.28rem;
    color: #000000;
    font-family: 'poppinssemibold';
}

.dashboard_box .Search_sec {
    min-width: 14.57rem;
}

.dashboard_box {
    border: 1px solid #e4e4e4;
    border-radius: 0.71rem;
    padding: 2.5rem;
}

.dashboard_box .filter {
    width: 80px;
    height: 3rem;
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #b8babe;
    background-color: #fff;
}

/* main_notification1_page */

.main_notification1 {

    border: 1px solid #0000003d;
    border-radius: 6px;
}

.main_notification1 .new_notifcation {

    padding: 8px 14px;
    border-bottom: 1px solid #00000080;
}

.main_notification1 .new_notifcation .notification_name h2 {
    font-size: 1.21rem;
    font-family: 'poppinsmedium';
    color: rgb(53, 56, 66);
    line-height: 1.2;
}

/* main_notification1_page */


.header_search .eOPJCx {
    margin: 0;
    float: right;
    position: absolute;
    right: 12px;
}

.header_search .clear-icon {
    display: none;
}

header form .btn_search i {
    opacity: 0;
}

.linkedinDiv {
    border-top: 1px solid #6c6c6c !important;
    width: 100% !important;
}

.alignCen {
    text-align: center !important;
}




header .menu_header .navbar-text a:hover {
    text-decoration: none;
}

,
header .menu_header .navbar-text a:hover {
    text-decoration: none;
}

.login_menu ul {
    margin-bottom: 0;
}

.list_wrapper .box_list {
    position: relative;
    padding-bottom: 64px;
}

.list_wrapper .box_list .btn_box {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 15px 15px 15px;
}

.our_service_section {
    height: auto;
}

.our_photo_section {
    margin-top: -88px;
}